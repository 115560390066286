export const CREATE_TITLE = 'Create Issue';
export const EDIT_TITLE = 'Edit Issue';
export const FAILED_CREATE = 'Issue couldn\'t be created';
export const FAILED_SAVE = 'Issue couldn\'t be saved';

export const CREATE_TITLE_SPECIAL = 'Create Special Issue';
export const EDIT_TITLE_SPECIAL = 'Edit Special Issue';
export const FAILED_CREATE_SPECIAL = 'Special Issue couldn\'t be created';
export const FAILED_SAVE_SPECIAL = 'Special Issue couldn\'t be saved';
export const SENT_TO_WOL = 'SentToWOL';
